export default {
  computed: {
    menuItem () {
      return menuInfo => {
        if (!this.hasChild(menuInfo) && menuInfo?.children?.length === 1) {
          return menuInfo?.children[0]
        } else {
          return menuInfo
        }
      }
    }
  },
  methods: {
    /**
     * 是否展示父级菜单
     * @param menuInfo
     * @returns {boolean}
     */
    hasChild (menuInfo) {
      return !!(menuInfo?.children?.length > 1 || menuInfo?.meta?.showAlways)
    }
  }
}
